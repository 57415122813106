<template>
	<div>
		<table
			v-for="(item, index) in bankList"
			:key="index"
			:id="`bank-${item.id}`"
			class="bank-list"
			:class="item.bankOpen ? 'open' : 'closed'"
			@click="showSummary(item, $event)"
		>
			<tr>
				<th class="header-row">{{ languageStrings.idText }}</th>
				<td class="data-row" :class="item.bankOpen && item.id == appDataBus?.blinkBankid ? 'blink' : ''">{{ item.id }}</td>
			</tr>
			<tr>
				<th class="header-row">{{ languageStrings.bankOwnerText }}</th>
				<td class="data-row">{{ item.cashierDisplayName }}</td>
			</tr>
			<tr>
				<th class="header-row">{{ languageStrings.casinoIdText }}</th>
				<td class="data-row">{{ item.casinoId }}</td>
			</tr>
			<tr>
				<th class="header-row">{{ languageStrings.openDateText }}</th>
				<td class="data-row">{{ item.openDate }}</td>
			</tr>
			<tr>
				<th class="header-row">{{ languageStrings.closeDateText }}</th>
				<td v-if="item.bankOpen" class="open-text">OPEN</td>
				<td v-else>{{ item.closeDate }}</td>
			</tr>
			<tr>
				<th>{{ languageStrings.balanceText }}</th>
				<td class="data-row">
					{{ systemCurrencyTool.formatCurrency(item.currentBalanceCOC, systemCurrencyTool.displayType.minorWholeOrFull) }}
				</td>
			</tr>
			<tr v-if="item.bankOpen">
				<th class="header-row">{{ languageStrings.actionsText }}</th>
				<td v-if="item.bankOpen" class="data-row">
					<span
						id="showCashierBank"
						v-if="item.currentUsersOpenBank && cashierState.isCashier"
						class="btn"
						:title="`Begin transactions with bank# ${item.id}`"
						@click="showCashierBank(item.id)"
						>{{ languageStrings.viewText }}</span
					>
					<span
						id="closeCashierBank"
						v-if="cashierState.isSiteAdmin || cashierState.isManager || (item.currentUsersOpenBank && cashierState.isCashier)"
						class="btn"
						:title="`Close bank# ${item.id}`"
						@click="closeCashierBank(item.id)"
						>{{ languageStrings.closeText }}</span
					>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import router from "@/router/index";
import store from "@/store/index";

export default {
	name: "ListBanksTableMobile",
	props: {
		cashierState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		appDataBus: Object,
		bankList: Array,
		bankHasAction: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			currencyInfo: this.cashierState.currencyInfo,
			currentBank: {},
		};
	},
	mounted() {
		if (this.appDataBus?.blinkBankid) {
			setTimeout(() => {
				this.eventBus.emit("removeAppDataBus", "blinkBankid");
			}, 5000);
		}
	},
	methods: {
		showSummary(item, $event) {
			this.eventBus.emit("showCashierBankSummary", { item, $event });
		},
		showCashierBank(cashierBankId = null, e) {
			let globalState = store.state;
			globalState.cashierState.cashierBankId = cashierBankId;
			this.session.add("cashierBankId", cashierBankId);
			this.eventBus.emit("updateCashierState", globalState.cashierState);
			if (!this.cashierState?.currentBank) return false;

			router.push("/bankView");
		},
		closeCashierBank(cashierBankId) {
			this.eventBus.emit("closeCashierBank", cashierBankId);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bank-list {
	width: 100%;
	text-align: center;
	position: relative;
}

.bank-list tr {
	cursor: pointer;
}

.bank-list tr {
	transition: background-color 0.3s;
}

.bank-list tr.data-row:hover,
.bank-list tr:nth-child(2n):not(.open):hover {
	background-color: #dfdfdf;
	color: #000;
}

.bank-list tr:nth-child(2n):not(.open) {
	background-color: #414650;
}

.debug {
	display: none;
}

.open-text {
	padding: 1px 5px;
	background-color: #0f0;
	color: #000;
	font-weight: 700;
}

.bank-list {
	width: 95%;
	text-align: left;
	border-collapse: collapse;
	margin: 15px auto 2em;
}

.bank-list th,
.bank-list td {
	border: 1px #000 solid;
	padding: 5px 10px;
}

.bank-list th {
	width: 6em;
	color: #fff;
	background-color: #000;
}

.details {
	overflow-wrap: anywhere;
}

.btn {
	text-align: center;
}
</style>
