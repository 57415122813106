<template>
	<div>
		<table class="cashier-banks">
			<tr class="header-row">
				<th>{{ languageStrings.idText }}</th>
				<th>{{ languageStrings.bankOwnerText }}</th>
				<th>{{ languageStrings.casinoIdText }}</th>
				<th>{{ languageStrings.openDateText }}</th>
				<th>{{ languageStrings.closeDateText }}</th>
				<th>{{ languageStrings.balanceText }}</th>
				<th v-if="bankHasAction">{{ languageStrings.actionsText }}</th>
			</tr>
			<tr
				class="data-row"
				v-for="(item, index) in bankList"
				:key="index"
				:class="item.bankOpen ? 'open' : 'closed'"
				:title="
					`${languageStrings.clickThisRowBankSummary} ${item.id}${item.currentUsersOpenBank ? `${languageStrings.orClickViewButton}` : ''}.`
				"
				:id="`bank-${item.id}`"
				@click="showSummary(item, $event)"
			>
				<td :class="item.bankOpen && item.id == appDataBus?.blinkBankid ? 'blink' : ''" style="overflow-wrap: normal">{{ item.id }}</td>
				<td>{{ item.cashierDisplayName }}</td>
				<td>{{ item.casinoId }}</td>
				<td>{{ item.openDate }}</td>
				<td v-if="item.bankOpen" class="open-text">{{ languageStrings.OPEN }}</td>
				<td v-else>{{ item.closeDate }}</td>
				<td>{{ systemCurrencyTool.formatCurrency(item.currentBalanceCOC, systemCurrencyTool.displayType.minorWholeOrFull) }}</td>
				<td v-if="item.bankOpen">
					<span
						id="showCashierBank"
						v-if="item.currentUsersOpenBank && cashierState.isCashier"
						class="btn"
						:title="`Begin transactions with bank# ${item.id}`"
						@click="showCashierBank(item.id)"
						>{{ languageStrings.viewText }}</span
					>
					<span
						id="closeCashierBank"
						v-if="cashierState.isSiteAdmin || cashierState.isManager || (item.currentUsersOpenBank && cashierState.isCashier)"
						class="btn"
						:title="`Close bank# ${item.id}`"
						@click="closeCashierBank(item.id)"
						>{{ languageStrings.closeText }}</span
					>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import router from "@/router/index";
import store from "@/store/index";

export default {
	name: "ListBanksTable",
	props: {
		cashierState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		appDataBus: Object,
		bankList: Array,
		bankHasAction: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			currencyInfo: this.cashierState.currencyInfo,
			currentBank: {},
		};
	},
	watch: {},
	created() {},
	mounted() {
		if (this.appDataBus?.blinkBankid) {
			setTimeout(() => {
				this.eventBus.emit("removeAppDataBus", "blinkBankid");
			}, 5000);
		}
	},
	methods: {
		showSummary(item, $event) {
			this.eventBus.emit("showCashierBankSummary", { item, $event });
		},
		showCashierBank(cashierBankId = null, e) {
			let globalState = store.state;
			globalState.cashierState.cashierBankId = cashierBankId;
			this.session.add("cashierBankId", cashierBankId);
			this.eventBus.emit("updateCashierState", globalState.cashierState);
			if (!this.cashierState?.currentBank) return false;

			router.push("/bankView");
		},
		closeCashierBank(cashierBankId) {
			this.eventBus.emit("closeCashierBank", cashierBankId);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cashier-banks {
	width: 100%;
	text-align: center;
	position: relative;
}

.cashier-banks tr {
	cursor: pointer;
}

.cashier-banks tr {
	transition: background-color 0.3s;
}

.cashier-banks tr.data-row:hover,
.cashier-banks tr:nth-child(2n):not(.open):hover {
	background-color: #dfdfdf;
	color: #000;
}

.cashier-banks tr:nth-child(2n):not(.open) {
	background-color: #414650;
}

.debug {
	display: none;
}

.open-text {
	padding: 1px 5px;
	background-color: #0f0;
	color: #000;
	font-weight: 700;
}

.btn {
	text-align: center;
}
</style>
