<template>
	<div id="listCashierBank" class="input-heading">
		<div id="openedBanksButton" class="checkbox-button btn inline">
			<label for="openedBanksCheckbox">{{ languageStrings.openedBanksOnly }}</label>
			<input type="checkbox" id="openedBanksCheckbox" v-model="openedBanks" />
		</div>
		<h3>List Banks</h3>
		<div id="paging">
			<label for="limitOptions">Limit List</label>
			<select v-model="limit" id="limitOptions">
				<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
			</select>
			<button class="prev-button" type="button" @click="previousPage($event)">{{ languageStrings.previous }}</button>
			<button class="next-button" type="button" @click="nextPage($event)">{{ languageStrings.next }}</button>
			<span :currentPage="currentPage">page {{ currentPage }}</span>
		</div>
		<div class="bank-lists-container">
			<div v-if="!getListComplete" id="bank-list-loading">
				<span class="loading-icon"></span>
				Loading List
			</div>
			<ListBanksTableMobile
				v-if="isMobile && bankList && bankList.length > 0"
				:inGameCurrencyTool="inGameCurrencyTool"
				:systemCurrencyTool="systemCurrencyTool"
				:appDataBus="appDataBus"
				:bankList="bankList"
				:cashierState="cashierState"
				:cashierBankId="cashierBankId"
				:bankHasAction="bankHasAction"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
			/>
			<ListBanksTable
				v-if="!isMobile && bankList && bankList.length > 0"
				:inGameCurrencyTool="inGameCurrencyTool"
				:systemCurrencyTool="systemCurrencyTool"
				:appDataBus="appDataBus"
				:bankList="bankList"
				:cashierState="cashierState"
				:cashierBankId="cashierBankId"
				:bankHasAction="bankHasAction"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
			/>
			<transition name="fade">
				<component
					:is="currentComponent"
					:casinoList="casinoList"
					:cashierState="cashierState"
					:cashierBankId="cashierBankId"
					:inGameCurrencyTool="inGameCurrencyTool"
					:systemCurrencyTool="systemCurrencyTool"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
			</transition>
			<div v-if="(!bankList || !bankList.length > 0) && getListComplete">
				<h2>No Banks Found</h2>
			</div>
		</div>
	</div>
	<dialog id="close-dialog">
		<div>
			<p id="dialog-text"> 
				{{ `${this.languageStrings.closeBankMessage}, ${this.openBankMessage}` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="closeCashierBank(this.cashierBankId)">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
			</div>
		</div>
	</dialog>

</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router/index";
import store from "@/store/index";
import CashierBankSummary from "@/components/CashierBankSummary";
import ListBanksTableMobile from "@/components/ListBanksTableMobile";
import ListBanksTable from "@/components/ListBanksTable";
import { onBeforeUnmount } from "vue";

export default {
	name: "ListCashierBank",
	components: {
		CashierBankSummary,
		ListBanksTableMobile,
		ListBanksTable,
	},
	props: {
		casinoList: Array,
		cashierState: Object,
		appDataBus: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		isMobile: Boolean,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			isCashier: false,
			isReporter: false,
			isSiteAdmin: false,
			isManager: false,
			userId: "",
			casinoId: "",
			bankList: [],
			currentBank: {},
			closeBankId: "",
			offset: 0,
			limit: 20,
			pages: 0,
			currentPage: 1,
			isLastPage: false,
			openedBanks: true,
			action: "",
			banksOpen: 0,
			bankHasAction: false,
			checkboxButtonWrapper: null,
			getListComplete: false,
			currentComponent: null,
			cashierBankId: null,
			currencyInfo: this.cashierState.currencyInfo,
			inputDialog: null,
			openBankMessage: "",
		};
	},
	watch: {
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.listCashierBanks();
		},
		openedBanks() {
			this.currentPage = 1;
			this.offset = 0;
			this.listCashierBanks();
		},
	},
	created() {
		this.eventBus.on("closeCashierBankSummaryModal", () => {
			this.currentComponent = null;
		});
		this.eventBus.on("showCashierBankSummary", (payload) => {
			this.showSummary(payload.item, payload.$event);
		});
		this.eventBus.on("closeCashierBank", (payload) => {
			this.showCloseBankDialog();
		});
		onBeforeUnmount(() => {
			const checkboxButton = document.getElementById("openedBanksButton");
			checkboxButton.removeEventListener("click", this.checkboxButtonWrapper);
			this.eventBus.off("closeCashierBankSummaryModal");
			this.eventBus.off("closeCashierBank");
		});
	},
	mounted() {
		this.listCashierBanks();
		const checkboxButton = document.getElementById("openedBanksButton");
		const openedBanksCheckbox = document.getElementById("openedBanksCheckbox");
		this.inputDialog = document.getElementById("close-dialog");
		this.checkboxButtonWrapper = function(e) {
			if (e.target.id == "openedBanksButton") {
				openedBanksCheckbox.checked = openedBanksCheckbox.checked == true ? false : true;
				openedBanksCheckbox.dispatchEvent(new Event("change", { bubbles: true }));
			}
		};
		checkboxButton.addEventListener("click", this.checkboxButtonWrapper);
		let thisInstance = this;
		if (this.appDataBus?.blinkBankid) {
			setTimeout(() => {
				thisInstance.eventBus.emit("removeAppDataBus", "blinkBankid");
			}, 5000);
		}
	},
	methods: {
		async showSummary(item, e) {
			if (e.target.id !== "showCashierBank" && e.target.id !== "closeCashierBank") {
				this.cashierBankId = item.id;
				this.currentComponent = "CashierBankSummary";
			}
		},
		async listCashierBanks() {
			let globalState = store.state;
			this.getListComplete = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			try {
				// get one more item than requested to see if a second page exists
				let currentLimit = Number(this.limit) + 1;

				this.isSiteAdmin = this.cashierState.isSiteAdmin;
				this.isCashier = this.cashierState.isCashier;
				this.isReporter = this.cashierState.isReporter;
				this.isManager = this.cashierState.isManager;
				this.isHelpDesk = this.cashierState.isHelpDesk;

				let bankList = await sharedScripts.getBanksList(this, this.openedBanks, currentLimit, this.offset);

				let fetchStatus = sharedScripts.checkFetchErrors(bankList, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.bankList = bankList;
					if (fetchStatus.message !== "noResults") this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					this.getListComplete = true;
					return false;
				}

				this.getListComplete = true;

				if (bankList?.length <= this.limit) {
					this.isLastPage = true;
				}
				// remove extra item if a second page exists
				if (!this.isLastPage && Array.isArray(bankList)) bankList.pop();

				let currentBank = {};
				let userId = this.cashierState.userId;
				this.banksOpen = 0;
				if (Array.isArray(bankList)) {
					bankList.forEach((item) => {
						item.openDate = new Date(item.openDate);
						item.closeDate = item.closeDate ? new Date(item.closeDate) : "";
						item.openDate = item.openDate.toLocaleTimeString([], this.dateOptions);
						item.closeDate = item.closeDate ? item.closeDate.toLocaleTimeString([], this.dateOptions) : "";
						item.bankOpen = item.closeDate ? false : true;
						item.currentUsersOpenBank = !item.closeDate && item.cashierUserId === userId ? true : false;
						if (item.bankOpen) this.banksOpen++;
						if (item.currentUsersOpenBank) currentBank = item;
					});
				} else {
					this.status = fetchStatus;
					this.eventBus.emit("updateStatus", this.status);
				}

				if (this.banksOpen > 0) this.bankHasAction = true;

				this.currentBank = currentBank ? currentBank : null;
				globalState.cashierState.currentBank = this.currentBank;
				this.session.add("currentBank", this.currentBank);
				this.eventBus.emit("updateCashierState", globalState.cashierState);
				this.bankList = bankList.sort((a, b) => (a.bankOpen ? -1 : 1));
			} catch (e) {
				this.status.code = e;
				this.status.message = null;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		showCashierBank(cashierBankId = null, e) {
			let globalState = store.state;
			globalState.cashierState.cashierBankId = cashierBankId;
			this.session.add("cashierBankId", cashierBankId);
			this.eventBus.emit("updateCashierState", globalState.cashierState);
			if (!this.cashierState?.currentBank) return false;

			router.push("/bankView");
		},
		clearPlayerData() {
			let STATE = this.cashierState;
			STATE.playerAccount = {};
			this.playerDisplayName = "";
			this.playerUserId = "";
			this.playerRegularAU = 0;
			this.playerPromoAU = 0;
			this.eventBus.emit("updateCashierState", STATE);
		},
		async closeCashierBank(cashierBankId = null) {
			let globalState = store.state;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				this.closeModal();
				return false;
			}

			try {
				let closeStatus = await this.closeBank(this, cashierBankId);

				let fetchStatus = sharedScripts.checkFetchErrors(closeStatus, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = `Close Bank failed: ${fetchStatus.message ? fetchStatus.message : fetchStatus.code}`;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					this.closeModal();
					return;
				}

				this.banksOpen--;
				if (this.banksOpen > 0) this.bankHasAction;

				globalState.banksOpen = this.banksOpen;
				globalState.bankHasAction = this.bankHasAction;

				this.clearPlayerData();

				// Check if session needs to be refreshed
				let success = await this.authenticationCheck(this);
				if (success.hasOwnProperty("ok") && !success.ok) {
					this.serverStatus.serverBusy = false;
					this.serverStatus.busyText = "";
					this.closeModal();
					return false;
				}

				let bankSummary = await this.getCashierBankSummary(this, cashierBankId);

				this.eventBus.emit("printTicket", this.prepareClosingReceipt(this.cashierState, bankSummary, this.systemCurrencyTool));

				this.eventBus.emit("updateStatus", closeStatus);
				this.listCashierBanks();
			} catch (e) {
				this.status.code = e;
				this.status.message = null;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
			this.closeModal();
		},
		async previousPage(e) {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.listCashierBanks();
		},
		async nextPage() {
			if (this.bankList.length <= this.limit) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.listCashierBanks();
		},

		showCloseBankDialog() {

			this.openBankMessage = this.cashierBankId ? this.languageStrings.bank ` #${this.cashierBankId}` : this.languageStrings.yourBank;
			this.inputDialog.showModal();
		},

		closeModal() {
			this.inputDialog.close();
		},

	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#listCashierBank {
	margin-left: 20%;
	padding: 15px 15px 60px;
	height: calc(100vh - 10em);
	overflow: hidden;
}

.bank-lists-container {
	height: calc(100vh - 22em);
	overflow: hidden auto;
}

#bank-list-loading {
	text-align: center;
	display: grid;
	position: absolute;
	margin: auto;
	width: 100%;
	z-index: 200;
	background-color: rgb(0 0 0 / 80%);
	padding: 60px;
}

#close-dialog {
	margin: auto 15px;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
}

#close-dialog {
		margin: auto;
}

#dialog-text {
	text-align: center;
}

</style>
